<template>
  <div class="id">
    <DFSHeader active="采集地" />
    <Content />
    <DFSFooter />
  </div>
</template>

<script>
import DFSHeader from '@/components/DFSHeader';
import DFSFooter from '@/components/DFSFooter';
import Content from './components/Content.vue';


export default {
  name: 'Collection',
  components: {
    DFSHeader,
    DFSFooter,
    Content,
  }
}
</script>

<style scoped>
</style>
